import { createApi } from '@reduxjs/toolkit/query/react';
import { IApiResponse, IPaginate } from '../../interfaces/common';
import { baseQuery } from './common';
import { IPatientBill, IPatientBillItem } from '../../interfaces/patients';

export const patientsBillsApi = createApi({
	reducerPath: 'patients-bills',
	baseQuery: baseQuery,
	tagTypes: ['Patients bills', 'Patient bill'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		getPaientBills: builder.query<
			IApiResponse<IPaginate<IPatientBill[]>>,
			{
				pagination: { page: number; limit: number };
				status: 'draft' | 'cancelled';
				startDate?: string;
				endDate?: string;
				searchTerm?: string;
				patientStatus?: 'active' | 'deceased';
				filterBy?: 'creationDate' | 'lastModifiedDate' | 'patientStatus' | 'amount';
				startingAmount?: string;
				endingAmount?: string;
			}
		>({
			query: ({
				pagination: { page = 1, limit = 10 },
				status,
				startDate,
				endDate,
				searchTerm,
				patientStatus,
				startingAmount,
				endingAmount,
				filterBy,
			}) =>
				`patient-bills?page=${page}&limit=${limit}&status=${status}${startDate ? `&startDate=${startDate}` : ''}${
					endDate ? `&endDate=${endDate}` : ''
				}${searchTerm ? `&searchTerm=${searchTerm}` : ''}${patientStatus ? `&patientStatus=${patientStatus}` : ''}${
					startingAmount ? `&startingAmount=${startingAmount}` : ''
				}${endingAmount ? `&endingAmount=${endingAmount}` : ''}${filterBy ? `&filterBy=${filterBy}` : ''}`,
			providesTags: [{ type: 'Patients bills', id: 'LIST' }],
		}),
		getPatientBillById: builder.query<IApiResponse<IPatientBillItem>, string>({
			query: (id) => `/patient-bills/${id}`,
			keepUnusedDataFor: 300,
			providesTags: (result, error, arg) => [{ type: 'Patient bill', id: arg }],
		}),
		cancelPatientBill: builder.mutation<
			{
				id: string;
				data: {
					reasonForCancellation: string;
					cancelledById: string;
					cancelledByName: string;
				};
			},
			Partial<{
				id: string;
				data: {
					reasonForCancellation: string;
					cancelledById: string;
					cancelledByName: string;
				};
			}>
		>({
			query({ id, data }) {
				return {
					url: `patient-bills/${id}/cancel`,
					method: 'PATCH',
					body: data,
				};
			},
		}),
		generateInvoiceForBill: builder.mutation<
			{
				patientId: string;
				data: {
					itemIds?: string[];
					creatorId: string;
					creatorName: string;
				};
			},
			Partial<{
				patientId: string;
				data: {
					itemIds?: string[];
					creatorId: string;
					creatorName: string;
				};
			}>
		>({
			query({ patientId, data }) {
				return {
					url: `/patient-bills/${patientId}/generateInvoice`,
					method: 'PATCH',
					body: data,
				};
			},
		}),
		generateBillToExistingInvoice: builder.mutation<
			{
				patientId: string;
				invoiceId: string;
				data: {
					itemIds: string[];
				};
			},
			Partial<{
				patientId: string;
				invoiceId: string;
				data: {
					itemIds: string[];
				};
			}>
		>({
			query({ patientId, invoiceId, data }) {
				return {
					url: `/patient-bills/${patientId}/addToInvoice/${invoiceId}`,
					method: 'PATCH',
					body: data,
				};
			},
		}),
		addToGeneratedBill: builder.mutation<
			{
				patientId: string;
				invoiceId: string;
				data: {
					itemIds: string[];
					creatorId: string;
					creatorName: string;
				};
			},
			Partial<{
				patientId: string;
				invoiceId: string;
				data: {
					itemIds: string[];
					creatorId: string;
					creatorName: string;
				};
			}>
		>({
			query({ patientId, invoiceId, data }) {
				return {
					url: `/api/patient-bills/${patientId}/addToInvoice/${invoiceId}`,
					method: 'PATCH',
					body: data,
				};
			},
		}),
	}),
});

export const {
	useGetPaientBillsQuery,
	useGetPatientBillByIdQuery,
	useCancelPatientBillMutation,
	useGenerateInvoiceForBillMutation,
	useGenerateBillToExistingInvoiceMutation,
	useAddToGeneratedBillMutation,
} = patientsBillsApi;
